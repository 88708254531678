// store/filterSlice.ts
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface LendersFilterState {
  amount: string[];
  tenor: string[];
  country: string[];
  lenderType: string[];
  interestRate: string | null;
  loanType: string | null;
  currency: string;
  calc_tenor: string;
  selected_lender: string;
  rating: number[];
  searchTerm: string | null;
  url?: string;
  orderBy: string;
  correlation: {
    amount?: {[x: string]: number};
    tenor?: {[x: string]: number};
    lenderType?: {[x: string]: number};
    country?: {[x: string]: number};
  };
  searchTotal: number;
}

const initialState: LendersFilterState = {
  amount: [],
  tenor: [],
  lenderType: [],
  country: [],
  rating: [],
  interestRate: null,
  loanType: null,
  currency: "NGN",
  calc_tenor: "Months",
  selected_lender: "",
  searchTerm: null,
  orderBy: "Most popular",
  correlation: {
    amount: {},
    tenor: {},
    lenderType: {},
    country: {},
  },
  searchTotal: 0,
};

const setCorrelationToSessionStorage = (correlation: {
  amount?: {[x: string]: number};
  tenor?: {[x: string]: number};
  lenderType?: {[x: string]: number};
  country?: {[x: string]: number};
}) => {
  sessionStorage.setItem("lender_correlation", JSON.stringify(correlation));
};

const updateURLParams = (state: LendersFilterState) => {
  const params = new URLSearchParams();
  const mappings: Partial<Record<keyof LendersFilterState, string>> = {
    amount: state.amount.map((val) => encodeURIComponent(val)).join(","),
    tenor: state.tenor.map((val) => encodeURIComponent(val)).join(","),
    country: state.country.map((val) => encodeURIComponent(val)).join(","),
    lenderType: state.lenderType
      .map((val) => encodeURIComponent(val))
      .join(","),
    rating: state.rating.join(","),
    interestRate: state.interestRate
      ? encodeURIComponent(state.interestRate)
      : "",
    loanType: state.loanType ? encodeURIComponent(state.loanType) : "",
    orderBy: state.orderBy ? encodeURIComponent(state.orderBy) : "",
  };

  Object.entries(mappings).forEach(([key, value]) => {
    if (value) params.set(key, value.toString());
  });

  const newURL = `${
    state.url || window.location.pathname
  }?${params.toString()}`;
  window.history.pushState({}, "", newURL);
};

const loadFromURLParams = (state: LendersFilterState) => {
  const params = new URLSearchParams(window.location.search);
  const mappings: {[key in keyof LendersFilterState]?: string} = {
    amount: "amount",
    tenor: "tenor",
    country: "country",
    lenderType: "lenderType",
    rating: "rating",
    interestRate: "interestRate",
    loanType: "loanType",
    currency: "currency",
    orderBy: "orderBy",
  };

  Object.entries(mappings).forEach(([stateKey, paramKey]) => {
    const value = params.get(paramKey);
    const key = stateKey as keyof LendersFilterState;

    if (value) {
      if (Array.isArray(state[key])) {
        const arrayValue = value
          .split(",")
          .map((val: string) => decodeURIComponent(val));
        (state[key] as any) = arrayValue;
      } else {
        (state[key] as any) = decodeURIComponent(value);
      }
    }
  });
};

const clearURLParams = () => {
  const newURL = `${window.location.pathname}`;
  window.history.pushState({}, "", newURL);
};

const lendersFilterSlice = createSlice({
  name: "lendersFilter",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<Partial<LendersFilterState>>) {
      Object.assign(state, action.payload);
      updateURLParams(state);
    },
    loadFilter(state) {
      loadFromURLParams(state);
    },
    clearFilters: () => {
      clearURLParams();
      return initialState; // Reset filter state to initial state
    },
    setSelectedLender(state, action: PayloadAction<any>) {
      state.selected_lender = action.payload;
    },
    setTotal(state, action: PayloadAction<number>) {
      state.searchTotal = action.payload;
    },
    setCorrelation(
      state,
      action: PayloadAction<
        Partial<{
          amount: {[x: string]: number};
          tenor: {[x: string]: number};
          lenderType: {[x: string]: number};
          country: {[x: string]: number};
        }>
      >
    ) {
      if (action.payload.amount) {
        state.correlation.amount = action.payload.amount;
      }
      if (action.payload.tenor) {
        state.correlation.tenor = action.payload.tenor;
      }
      if (action.payload.lenderType) {
        state.correlation.lenderType = action.payload.lenderType;
      }
      if (action.payload.country) {
        state.correlation.country = action.payload.country;
      }

      setCorrelationToSessionStorage(state.correlation);
    },
  },
});

export const {
  setFilter,
  loadFilter,
  clearFilters,
  setSelectedLender,
  setCorrelation,
  setTotal,
} = lendersFilterSlice.actions;
export default lendersFilterSlice.reducer;
