// store/filterSlice.ts
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface FilterState {
  amount: string[];
  tenor: string[];
  country: string[];
  lenderType: string[];
  requirement: string[];
  loanType: string | null;
  interestRate: string | null;
  currency: string;
  calc_tenor: string;
  url?: string;
  orderBy: string;
  correlation: {
    amount?: {[x: string]: number};
    tenor?: {[x: string]: number};
    lenderType?: {[x: string]: number};
    country?: {[x: string]: number};
    requirement?: {[x: string]: number};
  };
}

const initialState: FilterState = {
  amount: [],
  tenor: [],
  lenderType: [],
  country: [],
  requirement: [],
  interestRate: null,
  loanType: null,
  currency: "NGN",
  calc_tenor: "Months",
  orderBy: "Most popular",
  correlation: {
    amount: {},
    tenor: {},
    lenderType: {},
    country: {},
    requirement: {},
  },
};

const setCorrelationToSessionStorage = (correlation: {
  amount?: {[x: string]: number};
  tenor?: {[x: string]: number};
  lenderType?: {[x: string]: number};
  country?: {[x: string]: number};
  requirement?: {[x: string]: number};
}) => {
  sessionStorage.setItem("loan_correlation", JSON.stringify(correlation));
};

const updateURLParams = (state: FilterState) => {
  const params = new URLSearchParams();
  const mappings: Partial<Record<keyof FilterState, string>> = {
    amount: state.amount.map((val) => encodeURIComponent(val)).join(","),
    tenor: state.tenor.map((val) => encodeURIComponent(val)).join(","),
    country: state.country.map((val) => encodeURIComponent(val)).join(","),
    lenderType: state.lenderType
      .map((val) => encodeURIComponent(val))
      .join(","),
    requirement: state.requirement
      .map((val) => encodeURIComponent(val))
      .join(","),
    interestRate: state.interestRate
      ? encodeURIComponent(state.interestRate)
      : "",
    loanType: state.loanType ? encodeURIComponent(state.loanType) : "",
    orderBy: state.orderBy ? encodeURIComponent(state.orderBy) : "",
  };

  Object.entries(mappings).forEach(([key, value]) => {
    if (value) params.set(key, value.toString());
  });

  const newURL = `${
    state.url || window.location.pathname
  }?${params.toString()}`;
  window.history.pushState({}, "", newURL);
};

const loadFromURLParams = (state: FilterState) => {
  const params = new URLSearchParams(window.location.search);
  const mappings: {[key in keyof FilterState]?: string} = {
    amount: "amount",
    tenor: "tenor",
    country: "country",
    lenderType: "lenderType",
    interestRate: "interestRate",
    loanType: "loanType",
    orderBy: "orderBy",
    // currency: "currency",
    // calc_tenor: "calc_tenor",
    requirement: "requirement",
  };

  Object.entries(mappings).forEach(([stateKey, paramKey]) => {
    const value = params.get(paramKey);
    const key = stateKey as keyof FilterState;

    if (value) {
      if (Array.isArray(state[key])) {
        const arrayValue = value
          .split(",")
          .map((val: string) => decodeURIComponent(val));
        (state[key] as any) = arrayValue;
      } else {
        (state[key] as any) = decodeURIComponent(value);
      }
    }
  });
};

const clearURLParams = () => {
  const newURL = `${window.location.pathname}`;
  window.history.pushState({}, "", newURL);
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<Partial<FilterState>>) {
      Object.assign(state, action.payload);
      // sessionStorage.setItem("filter", JSON.stringify(state));
      updateURLParams(state);
    },
    loadFilter(state) {
      // const savedFilter = sessionStorage.getItem("filter");
      // if (savedFilter) {
      //   Object.assign(state, JSON.parse(savedFilter));
      // }
      loadFromURLParams(state);
    },
    clearFilters: () => {
      // sessionStorage.setItem("filter", JSON.stringify(initialState));
      clearURLParams();
      return initialState; // Reset filter state to initial state
    },
    setCorrelation(
      state,
      action: PayloadAction<
        Partial<{
          amount: {[x: string]: number};
          tenor: {[x: string]: number};
          lenderType: {[x: string]: number};
          country: {[x: string]: number};
          requirement?: {[x: string]: number};
        }>
      >
    ) {
      // console.log("bad human", action.payload);
      if (action.payload.amount) {
        state.correlation.amount = action.payload.amount;
      }
      if (action.payload.tenor) {
        state.correlation.tenor = action.payload.tenor;
      }
      if (action.payload.lenderType) {
        state.correlation.lenderType = action.payload.lenderType;
      }
      if (action.payload.country) {
        state.correlation.country = action.payload.country;
        // console.log("good human", state.correlation.country);
      }
      if (action.payload.requirement) {
        state.correlation.requirement = action.payload.requirement;
      }

      setCorrelationToSessionStorage(state.correlation);
    },
  },
});

export const {setFilter, loadFilter, clearFilters, setCorrelation} =
  filterSlice.actions;
export default filterSlice.reducer;
